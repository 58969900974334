.AnswerQuestion {
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  color: white;
}

.answerCardIcon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px 0 0 10px;
  max-width: 40px;
  max-height: 40px;
  filter: invert(1);
}

@media (max-width: 500px) {
  #answerPage {
    width: fit-content;
    margin: 0 auto;
    gap: 0;
    box-sizing: border-box;
    padding: 30px 0;
    height: 80vh;
  }

  #answerPageAnswers {
    min-width: 90vw;
    width: fit-content;
    gap: 5px;
    margin: auto;
    padding: 0;
    box-sizing: border-box;
    padding: 5px;
  }

  #answerPageAnswerCard {
    box-sizing: border-box;
    padding: 5px;
    border-radius: 15px;
    width: 100%;
    height: 120px;
    margin: auto;
  }

  .answerCardIcon {
    max-width: 25px;
    max-height: 25px;
  }
}
