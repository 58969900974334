.cardStartPageWrapper {
  background-color: #6a6dd2;
  box-shadow: inset;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 230px;
  height: 130px;
  cursor: pointer;
  transition: background-color 0.2s;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.cardStartPageWrapper:hover {
  background-color: #a86eff;
  outline: 3px solid rgba(255, 255, 255, 0.508);
}

.quizCardBtns {
  position: absolute;
  top: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.iconBtn {
  background: none;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(192, 191, 191, 0.523);
  border: none;
  cursor: pointer;
  color: white;
}

.iconBtn:hover {
  background: rgba(192, 191, 191, 0.77);
}

.cardStartPageWrapper p {
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
}

.inputPin {
  text-align: center;
  padding: 10px;
  border-radius: 40px;
  border: none;
  width: 80%;
}

.inputPin:focus {
  outline: none;
}
