.nextQuestionBtn {
  position: absolute;
  right: 0;
  height: fit-content;
  width: max-content;
  box-sizing: border-box;
  padding: 10px;
  margin-right: 50px;
  margin-top: 70px;
  border-radius: 10px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.answerQuizPage {
  overflow: hidden;
  position: fixed;
}
