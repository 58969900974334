.countBar {
  width: 35px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-sizing: border-box;
  padding-top: 10px;
  color: black;
  font-weight: bold;
  max-height: 30rem;
  color: white;
}
