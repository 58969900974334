#PlayerNameGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto;
  gap: 10px;
}

.playerEntry {
  background: linear-gradient(145deg, #6c7a89, #556270);
  border-radius: 12px;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: default;
}

.playerEntry:hover {
  transform: translateY(+2px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  background: linear-gradient(145deg, #556270, #766c89);
}

.playerName {
  color: #ecf0f1;
  font-size: 1rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.kickButton {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px;
  font-size: 0.8rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .playerEntry {
    justify-content: center;
    max-width: none;
    width: auto;
  }
}
