.playerName {
  color: white;
}

.resultListTile {
  padding: 1rem;
  width: 60vw;
  height: 1.3rem;
  margin: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  background-color: #6a6dd2;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.tileText {
  color: aliceblue;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .tileText {
    font-size: 1rem;
  }
  .resultListTile {
    height: 0.6rem;
  }
}

.tileStart {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#placementNumber {
  margin-right: 0.5rem;
}
