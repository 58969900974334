.createQuizPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
}

.createQuizTop {
  height: 50px;
  margin: 20px;
  background: white;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.quizTitleDiv {
  width: 70%;
}

.leftSidebar {
  width: max-content;
  min-height: 100%;
  max-height: 80vh;
  background-color: white;
  margin-left: 20px;
  border-radius: 20px;
  padding: 20px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  border: 5px solid white;
}

/* Hide scrollbar for Chrome, Safari and Opera*/
.leftSidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.leftSidebar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.questionMiniatureWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  font-weight: bold;
  gap: 10px;
}

.questionMiniature {
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
}

.createQuestionDiv {
  margin: 0 auto;
}

.addQuestionBtn {
  border: none;
  background: #6a6dd2;
  color: white;
  border-radius: 50px;
  height: 35px;
  width: 35px;
  min-width: 35px;
  min-height: 35px;
  padding: 8px;
  cursor: pointer;
  margin: 0 auto;
  margin-left: 70px;
}

.addQuestionBtn:hover {
  background: #7e80d2;
  transition: 0.3s;
}

.numAndDelBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.deleteQuestionBtn {
  background: none;
  border: none;
  width: fit-content;
  cursor: pointer;
}

.deleteQuestionBtn:hover {
  color: red;
  transition: 0.3s;
}

.finishQuizBtn {
  height: fit-content;
  width: max-content;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  background: #6a6dd2;
  color: white;
}

.finishQuizBtn:hover {
  background: #8e91e7;
}

.createqtnandsidebar {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1000px) {
  .createqtnandsidebar {
    display: flex;
    flex-direction: column-reverse;
  }

  .leftSidebar {
    margin: 0 auto;
    max-width: 90vw;
    width: 85vw;
    min-width: 80vw;
    flex-direction: row;
    min-height: 60px;
    max-height: 60px;
    gap: 5px;
    overflow-y: hidden;
    overflow-x: scroll;
    border: none;
  }

  .questionMiniatureWrapper {
    gap: 0px;
    margin-left: 10px;
  }

  .addQuestionBtn {
    margin: auto 10px;
  }
}
