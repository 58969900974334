.timeBox {
  border: 2px dashed rgba(255, 255, 0, 0.8);
  background: linear-gradient(
    145deg,
    rgba(255, 20, 147, 0.6),
    rgba(255, 165, 0, 0.6)
  );
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(255, 20, 147, 0.75),
    inset 0 0 10px rgba(255, 165, 0, 0.5);
  display: inline-block;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: shake 0.5s infinite alternate;
}

.timeBox:hover {
  transform: scale(1.1);
  box-shadow: 0 0 25px rgba(255, 20, 147, 1),
    inset 0 0 15px rgba(255, 165, 0, 0.75);
  animation: wiggle 0.2s infinite alternate;
}

@keyframes shake {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
}
