.chosenAnswers {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: fit-content;
  max-height: 600px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.376);
  justify-content: flex-end;
}

.bars {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.icons {
  border-top: 3px solid white;
  box-sizing: border-box;
  padding-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.answerIconWrapper{
  width: 40px;
  height: 40px;
  margin: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.answerIcon {
  max-width: 40px;
  max-height: 40px;
  filter: invert(1);
}
