#PostLobbyPage {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#ResultList {
  overflow-y: scroll;
}

#header {
  display: flex;
  box-sizing: border-box;
  padding: 30px 10px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

#postLobbyPageTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PostLobbyPagePlayer {
  box-sizing: border-box;
  padding: 20px !important;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.PostLobbyPagePlayer h1 {
  font-size: 1rem;
}

.postLobbyPagePlayerResult {
  margin: auto;
  margin-top: 200px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 15px;
  background: rgb(230, 85, 216);
}
