.questionStatsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.questionStatsTop {
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 95%;
  justify-content: space-between;
}

.questionStatsTop h1 {
  margin: 0 auto;
  box-sizing: border-box;
  margin: 0 150px;
  color: white;
  max-width: 100%;
  word-wrap: break-all;
}

.nextBtn {
  position: absolute;
  right: 0;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  background-color: #c62467;
  color: white;
}

.questionStatsFigures {
  display: flex;
  width: 90%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.playerStatsSection {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
}

#playerQuestionStatDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  width: 90vw;
  height: 90vh;
  box-sizing: border-box;
  padding-bottom: 70px;
  justify-content: space-between;
}

#playerScore,
#gainedThisRound {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
  box-sizing: border-box;
  width: 90vw;
  max-width: 300px;
  border-radius: 10px;
  font-weight: bold;
}

#playerScore {
  background: rgba(255, 255, 255, 0.262);
}

#gainedThisRound {
  background: rgb(255, 149, 0);
  gap: 5px;
}
