.lobbyTitle {
  color: white;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 10vw;
  font-size: 2.5rem;
  word-break: break-word;
}

.lobbyBtns {
  position: absolute;
  align-self: center;
  top: 60px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.lobbyCodeTitle {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  font-weight: normal;
}

.lobbyCodeTitleCode {
  font-weight: bold;
}

.joinInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}

.lobbyPageTop {
  min-height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 30px;
}

.playerNameGridDiv {
  display: flex;
  align-items: center;
  min-height: 50%;
}

.lobbyPageBottom {
  display: flex;
  min-height: 25%;
  height: 25%;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
}

@media (max-width: 500px) {
  .lobbyPage {
    box-sizing: border-box;
    padding: 20px;
    position: fixed;
  }

  .lobbyTitle {
    padding: 0 10px;
  }

  .lobbyBtns {
    top: 20px;
  }

  .lobbyPageBottom {
    font-size: 0.8rem;
  }
}
