.startPage {
  display: flex;
  flex-direction: column;
}

.startPageTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  justify-content: flex-end;
}

.cardContainer {
  display: flex;
  margin: auto;
  flex-direction: row;
  gap: 20px;
  /* flex-wrap: wrap;
  justify-content: center;
  padding: 65px; */
}

.myQuizzes {
  padding: 40px;
  padding-top: 0;
}
