.myQuizzesWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
}

.myQuizzesWrapper h3 {
  color: white;
}

.quizzes {
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 5px;
  overflow: scroll;
  height: 47vh;
}
