.PlayerCounter {
  border-radius: 50%;
  background-color: #6a6dd2;
  height: 3.2rem;
  width: 3.2rem;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

#playerCountText {
  text-align: center;
  font-size: 1.3rem;
  color: white;
}

